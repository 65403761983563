import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'patients',
    loadChildren: () => import('../../patients/patients.module').then(m => m.PatientsModule)
  },
  {
    path: 'registrations',
    loadChildren: () => import('../../registrations/registrations.module').then(m => m.RegistrationsModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'payroll',
    loadChildren: () => import('../../payroll/payroll.module').then(m => m.PayrollModule)
  }
];
