import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HttpUtilsService } from './shared/utils/http-utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router,
        private httpUtilsService: HttpUtilsService,
        private ngxSpinnerService:NgxSpinnerService) {
            this.httpUtilsService.loadingSubject.subscribe(loading => {
                if(loading){
                  this.ngxSpinnerService.show();
                } else{
                  this.ngxSpinnerService.hide();
                }
              });
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}