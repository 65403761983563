import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: '/registrations/list', title: 'OB Pre Registrations', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, role:'all', submenu: []
  },
  {
    path: '/patients/list', title: 'Patient List', icon: 'ft-menu', class: 'dropdown nav-item', isExternalLink: false, role:'all', submenu: []
  },
  {
    path: '/payroll/list', title: 'Payroll', icon: 'ft-menu', class: 'dropdown nav-item', isExternalLink: false, role:'payroll', submenu: []
  },
 
  
];
