<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title h5 fs-1" id="example-modal-sizes-title-lg">
            <ng-container>
                <ng-container>Change Password</ng-container>
            </ng-container>
        </div>
        <div class="float-right ir-2" style="margin-top:4px !important">
            <a class="mx-1" (click)="modal.dismiss()">
                <i class="ft-x font-medium-3 text-white" style="border-top: 2%;"></i>
            </a>
        </div>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="isLoading$ | async">
            <div class="overlay-layer bg-transparent">
                <div class="spinner spinner-lg spinner-success"></div>
            </div>
        </ng-container>
        <ng-container>
            <form class="form form-label-right" [formGroup]="changePassword">
                <div class="card-body response-list">
                    <div class="form-group mb-0">
                        <label>Current Password&nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-0">
                            <input [type]="cpasswordshown === true ?'password':'text'" class="form-control" name="existingPassword"
                            formControlName="existingPassword" placeholder="Current Password" required maxlength="20"
                            >
                            <div class="input-group-append">
                             <span [ngClass]="cpasswordshown===true? 'ft-eye':'ft-eye-off'"   (click)="cpasswordshown===true? cshowpassword(false):cshowpassword(true)" class="input-group-text"></span>
                            </div>
                        </div>
                       
                        <!-- <input type="password" class="form-control" name="existingPassword"
                            formControlName="existingPassword" placeholder="Current Password" required maxlength="20"
                            *ngIf="cpasswordshown===true">
                        <input type="text" class="form-control" name="existingPassword"
                            formControlName="existingPassword" placeholder="Current Password" required maxlength="20"
                            *ngIf="cpasswordshown===false">
                        <span class="p-viewer">
                            <i [ngClass]="cpasswordshown===true? 'ft-eye':'ft-eye-off'" aria-hidden="true"
                                (click)="cpasswordshown===true? cshowpassword(false):cshowpassword(true)"></i>
                        </span> -->
                        <span class="custom-error-css" *ngIf="isControlHasError('existingPassword', 'required')">
                            Required</span>&nbsp;
                    </div>
                    <div class="form-group mb-0">
                        <label>New Password&nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-0">
                            <input [type]="newpasswordshown === true ?'password':'text'" class="form-control" name="password"
                            formControlName="password" placeholder="New Password" required maxlength="20" (change)="checkSamePassword()"
                            >
                            <div class="input-group-append">
                             <span [ngClass]="newpasswordshown===true? 'ft-eye':'ft-eye-off'"   (click)="newpasswordshown===true? newshowpassword(false):newshowpassword(true)" class="input-group-text"></span>
                            </div>
                        </div>
                        <!-- <input type="password" class="form-control" name="password" formControlName="password"
                            placeholder="New Password" maxlength="20" required="true" (change)="checkSamePassword()"
                            *ngIf="newpasswordshown===true">
                        <input type="text" class="form-control" name="password" formControlName="password"
                            placeholder="New Password" maxlength="20" required="true" (change)="checkSamePassword()"
                            *ngIf="newpasswordshown===false">
                        <span class="p-viewer">
                            <i [ngClass]="newpasswordshown===true? 'ft-eye':'ft-eye-off'" aria-hidden="true"
                                (click)="newpasswordshown===true? newshowpassword(false):newshowpassword(true)"></i>
                        </span> -->
                        <span class="custom-error-css" *ngIf="isControlHasError('password', 'required')">
                            Required</span>&nbsp;
                        <span class="custom-error-css" *ngIf="isControlHasError('password', 'minlength')"> Minlength -
                            6</span>&nbsp;
                        <span class="custom-error-css" *ngIf="isControlHasError('password', 'maxlength')"> Maxlength -
                            20</span>&nbsp;
                        <span class="custom-error-css" *ngIf="samePassword">New Password shouldn't be same as Current
                            Password</span>
                    </div>
                    <div class="form-group mb-0">
                        <label>Password Strength </label>
                        <app-password-strength [passwordToCheck]="changePassword.get('password').value"
                            [minLength]='passwordMinLength' (passwordStrength)="onPasswordChange($event)">
                        </app-password-strength>
                    </div>
                    <div class="form-group mb-0">
                        <label>Confirm Password&nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-0">
                            <input [type]="newconpasswordshown === true ?'password':'text'" class="form-control" name="confirmPassword"
                            formControlName="confirmPassword" placeholder="Confirm Password" required maxlength="20"
                            >
                            <div class="input-group-append">
                             <span [ngClass]="newconpasswordshown===true? 'ft-eye':'ft-eye-off'"   (click)="newconpasswordshown===true? newconshowpassword(false):newconshowpassword(true)" class="input-group-text"></span>
                            </div>
                        </div>
                        <!-- <input type="password" class="form-control" name="confirmPassword"
                            formControlName="confirmPassword" placeholder="Confirm Password" maxlength="20"
                            required="true" *ngIf="newconpasswordshown===true">
                            <input type="text" class="form-control" name="confirmPassword"
                            formControlName="confirmPassword" placeholder="Confirm Password" maxlength="20"
                            required="true" *ngIf="newconpasswordshown===false">
                            <span class="p-viewer">
                                <i [ngClass]="newconpasswordshown===true? 'ft-eye':'ft-eye-off'" aria-hidden="true"
                                    (click)="newconpasswordshown===true? newconshowpassword(false):newconshowpassword(true)"></i>
                            </span> -->
                        <span class="custom-error-css"
                            *ngIf="isControlHasError('confirmPassword', 'required')">Required</span>&nbsp;
                        <span class="custom-error-css" *ngIf="isControlHasError('confirmPassword', 'minlength')">
                            Minlength -
                            6</span>&nbsp;
                        <span class="custom-error-css" *ngIf="isControlHasError('confirmPassword', 'maxlength')">
                            Maxlength - 20</span>
                        &nbsp;
                        <span class="custom-error-css" *ngIf="confirmPasswordError">Passwords doesn't match</span>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm btn-elevate mr-2" (click)="modal.dismiss()">Cancel</button>
        <ng-container>
            <button type="button" class="btn btn-primary btn-elevate btn-sm" (click)="save()">
                Save</button>
        </ng-container>
    </div>
</div>