export class AppSettings {

    // Static REST API Base Url
    //  static readonly API_ENDPOINT = 'http://localhost:3016/jmcapi'
     static readonly API_ENDPOINT ='https://jupitermed.vconnexservices.com/jmcapi';
  // Assign static or dynamic API

    static readonly SECRET_KEY = 'JMCCOVIDKEY';
    static readonly AUTH_SECRET_KEY = "JMC_COVIDSECRET";
    static readonly PAGE_SIZE = 15;
    static readonly PAGE_SIZE_payroll = 100;
    static readonly PAGE_SIZE_OPTIONS = [15,30,45,60];
    static readonly PAGE_SIZE_OPTIONS_payroll = [30,45,60,100];
  }
