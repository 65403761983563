import { Injectable } from '@angular/core';
  
import { ToastrService } from 'ngx-toastr';
  
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  constructor(private toastr: ToastrService) { }
  
  
  showSuccess(message:string, title:string){
    return this.toastr.success(message, title, {
      timeOut: 10000,
      //closeButton:true,
      positionClass: 'toast-top-center'
    });
  };

  showError(message:string, title:string){
    return this.toastr.error(message, title, {
      timeOut: 10000,
     // closeButton:true,
      positionClass: 'toast-top-center'
    
    });
  }
  showInfo(message, title){
      this.toastr.info(message, title)
  }
  
  showWarning(message, title){
      this.toastr.warning(message, title)
  }
  
}