import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'app/shared/services/app.service';
import { HttpUtilsService } from 'app/shared/utils/http-utils.service';
import { FormsService } from 'app/shared/services/forms.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'app/shared/utils/notification.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  loginUser: any = {};
  changePassword: FormGroup;
  confirmPasswordError: boolean = false;
  samePassword: boolean = false;
  passwordIsValid = false;
  password: string = '';
  passwordChange: boolean = true;
  hasFormErrors: boolean = false;
  viewLoading: boolean = false;
  passwordMinLength: number = 8;
  isLoading$: any; //loader for form fields
  cpasswordshown = true;
  newpasswordshown = true;
  newconpasswordshown = true;
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private router: Router,
    private FormsService: FormsService, private HttpUtilsService: HttpUtilsService,
    public modal: NgbActiveModal,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.loginUser = this.appService.getLocalStorageItem('jmcuser', true);

    this.changePassword = this.fb.group({
      existingPassword: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)])
    }, {
      validator: this.ConfirmedValidator('password', 'confirmPassword')
    });
    this.buildForm();
  }
  buildForm() {
    this.changePassword.get('password').setValidators([Validators.required, Validators.maxLength(20), Validators.minLength(6)]);
    this.changePassword.get('confirmPassword').setValidators([Validators.required, Validators.maxLength(20), Validators.minLength(6)]);
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.changePassword.controls[controlName].hasError(errorName);
  };

  cshowpassword(event) {
    console.log("current",event)
    this.cpasswordshown = event;
  }
  newshowpassword(event) {
    console.log("new",event)
    this.newpasswordshown = event;
  }
  newconshowpassword(event) {
    this.newconpasswordshown = event;
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.changePassword.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
    return result;
  }
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
        this.confirmPasswordError = true;
      } else {
        this.confirmPasswordError = false;
        matchingControl.setErrors(null);
      }
    }
  }
  checkSamePassword() {
    const controls = this.changePassword.controls;
    if (controls.existingPassword.value === controls.password.value) {
      this.samePassword = true;
      this.cdr.markForCheck();
    } else {
      this.samePassword = false;
      this.cdr.markForCheck();
    }
    // this.samePassword = controls.existingPassword.value !== controls.password.value;
  }
  // function to check the password strength
  onPasswordChange(event) {
    console.log(event)
    this.password = this.changePassword.controls['password'].value;
    this.passwordIsValid = event;
    this.cdr.markForCheck();
  }
  save() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.changePassword.controls;
    // console.log(controls)
    if (this.changePassword.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      // this.layoutUtilsService.showError('Please enter the required fields', '');
      this.viewLoading = false;
      this.cdr.markForCheck();
      return;
    }
    const queryparam = {
      UserId: this.loginUser.UserId,
      LoggedId: this.loginUser.UserId,
      OldPassword: controls.existingPassword.value,
      NewPassword: controls.password.value
    };
    this.FormsService.changePassword(queryparam).subscribe((res) => {
      if (!res.isFault) {
        // alert(res.responseData.message);
        this.modal.dismiss()
        this.notificationService.showSuccess(res.responseData.message, '');
        this.router.navigate(['/pages/login'], { relativeTo: this.activatedRoute });

      }
      else {
        this.notificationService.showError(res.responseData.message, '');
      }
    });
  }

}
