import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private http: HttpClient,private appService:AppService) { }
  private handleError<T>(operation = 'operation', result?: any) {
  
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return from(result);
    };
  }

  public getFormSubmission(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getFormSubmission', data);
  }
  public getAnestheticConsultFormSubmission(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getAnestheticConsultFormSubmission', data);
  }   
  public getBirthCertificateFormSubmission(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getBirthCertificateFormSubmission', data);
  }
  public getNeurologyFormSubmission(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getNeurologyFormSubmission', data);
  }
  public signin(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/signin', data);
  }   
  public updateStatus(data: any): Observable<any> {

    return this.http.post(AppSettings.API_ENDPOINT + '/updateStatus', data);
  }   

  public getPreRegistration(data: any): Observable<any> {

    return this.http.post(AppSettings.API_ENDPOINT + '/getPreRegistration', data,);
  } 
  public sendEmail(data: any): Observable<any> {

    return this.http.post(AppSettings.API_ENDPOINT + '/sendEmail', data);
  } 
  public getexportPreRegistrations(data: any): Observable<any> {

    return this.http.post(AppSettings.API_ENDPOINT + '/getAllPreRegistrations', data);
  } 

  // GET => get all the Physicians based on the pagination and filter conditions
  public getAllPreRegistrations(queryParams: any): Observable<any> {

    return this.http.post(`${AppSettings.API_ENDPOINT}/getAllPreRegistrations?pageSize=${queryParams.pageSize}&sortDir=${queryParams.sortOrder}&sortField=${queryParams.sortField}&start=${queryParams.pageNumber}`, queryParams.filter,).pipe(
        mergeMap(res => {
          return of(res);
        }),
        catchError(this.handleError('get-team', []))
      );
  }
  public getAllPatients(queryParams: any): Observable<any> {

    return this.http.post(`${AppSettings.API_ENDPOINT}/getAllPatients?pageSize=${queryParams.pageSize}&sortDir=${queryParams.sortOrder}&sortField=${queryParams.sortField}&start=${queryParams.pageNumber}`, queryParams.filter,).pipe(
        mergeMap(res => {
          return of(res);
        }),
        catchError(this.handleError('get-team', []))
      );
  }
  public getAllTimesheet(queryParams: any): Observable<any> {

    return this.http.post(`${AppSettings.API_ENDPOINT}/getAllTimesheet?pageSize=${queryParams.pageSize}&sortDir=${queryParams.sortOrder}&sortField=${queryParams.sortField}&start=${queryParams.pageNumber}`, queryParams.filter,).pipe(
        mergeMap(res => {
          return of(res);
        }),
        catchError(this.handleError('get-team', []))
      );
  }
  public getexportPatients(data: any): Observable<any> {

    return this.http.post(AppSettings.API_ENDPOINT + '/getAllPatients', data);
  }
  public getAllEmployees(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getAllEmployees', data);
  } 
  public getEmailTemplate(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getEmailTemplate', data);
  }    
  public updatePatientDetailAndStatus(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/updatePatientDetailAndStatus', data);
  }    
  public editPatient(data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/editPatient', data);
  } 
  public getPatient (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getPatient ', data);
  }   
  public createPatient (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/createPatient ', data);
  }   
  public forgotPassword (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/forgotPassword ', data);
  }   
  public resetPassword (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/resetPassword ', data);
  }  
  public changePassword (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/changePassword ', data);
  } 
  public getAllUsers(queryParams: any): Observable<any> {
    return this.http.post(`${AppSettings.API_ENDPOINT}/getAllUsers?pageSize=${queryParams.pageSize}&sortDir=${queryParams.sortOrder}&sortField=${queryParams.sortField}&start=${queryParams.pageNumber}`, queryParams.filter,).pipe(
        mergeMap(res => {
          return of(res);
        }),
        catchError(this.handleError('get-team', []))
      );
  }  
  public getexportUsers (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getAllUsers ', data);
  } 
  public createUser (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/createUser ', data);
  } 
  public getUser (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/getUser ', data);
  } 
  public editUser (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/editUser ', data);
  } 

  public convertBase64Image (data: any): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + '/convertBase64Image ', data);
  } 

}
