import { Injectable } from '@angular/core';
import { AppSettings} from '../../app.settings';
import { AppEncryptDecryptService } from './app-encrypt-decrypt';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private appEncryptDecryptService: AppEncryptDecryptService) {
  }
  // get the local storage item with decrypted value
  public getLocalStorageItem(key: string, parsingNeeded: boolean) {
    const itemVal = localStorage.getItem(key);
    if (itemVal == null) {
      return null;
    }
    const decryptedValue = this.appEncryptDecryptService.get(AppSettings.SECRET_KEY, itemVal);
    if (!parsingNeeded) {
      return decryptedValue;
    } else {
      return JSON.parse(decryptedValue);
    }
  }
 // set the local storage item with encrypted value
  public setLocalStorageItem(key: string, value: any, parsingNeeded: boolean) {
    if (!parsingNeeded) {
      return localStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, value));
    } else {
      return localStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, JSON.stringify(value)));
    }
  }

   // get the session storage item with decrypted value
  public getSessionStorageItem(key: string, parsingNeeded: boolean) {
    const itemVal = sessionStorage.getItem(key);
    if (itemVal == null) {
      return null;
    }
    const decryptedValue = this.appEncryptDecryptService.get(AppSettings.SECRET_KEY, itemVal);
    if (!parsingNeeded) {
      return decryptedValue;
    } else {
      return JSON.parse(decryptedValue);
    }
  }
  // set the local storage item with encrypted value
  public setSessionStorageItem(key: string, value: any, parsingNeeded: boolean) {
    if (!parsingNeeded) {
      return sessionStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, value));
    } else {
      return sessionStorage.setItem(key, this.appEncryptDecryptService.set(AppSettings.SECRET_KEY, JSON.stringify(value)));
    }
  }
  // get the time in sentence format
  timeConvert(num) {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    switch (hours) {
      case 0:
        return minutes + 'min ago';
      case 1:
        return hours + 'hr' + minutes + 'min ago';
      default:
        return hours + 'hr' + minutes + 'min ago';
    }
  };
  
  
     // get the format for phone number with /without extension
     getPhoneFormat(phoneNum: string): string
     {
       // tslint:disable-next-line:one-variable-per-declaration
       var newPhone = phoneNum.split(" ").join("")
   // console.log('new Phone' ,newPhone )
       let max: number = 14, min: number = 10;
       const areaCode: string = newPhone.substring(0, 3);
       const mid: string = newPhone.substring(3, 6);
       const lastFour: string = newPhone.substring(6, 10);
       const extens = newPhone.length - min;
       const extension: string = newPhone.substring(10, extens+min);
       // console.log('phoneNum', newPhone);
       // console.log('areaCode', areaCode);
       // console.log('mid', mid);
       // console.log('lastFour', lastFour);
       // console.log('extens', extens);
       // console.log('extension', extension);
       if (newPhone.length === min)
       {
         let phoneCode = '('+areaCode+') '+mid+'-'+lastFour;
         return phoneCode.trim(); // $"({areaCode}) {mid}-{lastFour}";
       }
       else if (newPhone.length > min && newPhone.length <= max)
       {
         let phoneCode = '('+areaCode+') '+mid+'-'+lastFour + ' Ext. ' +extension;
         return  phoneCode.trim(); // $"({areaCode}) {mid}-{lastFour} x{extension}";
       }
       else
       {
         return newPhone.trim();
       }
     }
   
  

   // get the date from Unix time
  unixDate(unixtime) {
    if (unixtime) {
      const u = new Date(unixtime);
      const month = u.getMonth() + 1;
      const dates = month + '/' + u.getDate() + '/' + u.getFullYear();
      return dates;
    } else {
      const dates = '';
      return dates;
    }
  };

   // get the date time from unix time
  public unixTime(unixtime) {
    if (unixtime) {
      const u = new Date(unixtime);
      const amOrPm = (u.getHours() < 12) ? 'AM' : 'PM';
      const hour = (u.getHours() < 12) ? u.getHours() : u.getHours() - 12;
      const month = u.getMonth() + 1;
      const minutes: number = u.getMinutes();
      let min: string;
      if (minutes < 10) {
        min = '0' + minutes.toString();
      } else {
        min = minutes.toString();
      }
      // const dates = month + '/' + u.getDate() + '/' + u.getFullYear() + ' ' + hour + ':' + min + ' ' + amOrPm;
      const dates =  hour + ':' + min + ' ' + amOrPm;
      return dates;
    } else {
      const dates = '';
      return dates;
    }
  }




}
