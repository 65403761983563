<div class="strength">
    <ul class="strengthBar">
      <li class="point" [style.background-color]="bar0"></li>
      <li class="point" [style.background-color]="bar1"></li>
      <li class="point" [style.background-color]="bar2"></li>
      <li class="point" [style.background-color]="bar3"></li>
    </ul>
    <br>
    <p style="margin-bottom: 0.5rem !important;">{{msg}}</p>
    <span *ngIf="msg !== 'Good'" class='font-11'>Your password must be minimum of {{minLengthData}} characters long, contain letters, a Uppercase letter, a special characters and a number.</span>
</div>